export const patientRxAuth = {
  SignUpWelcomeMessage:
    "Welcome {{firstName}}, activate your account to view the {{treatmentPlanTerm}} {{pracName}} wrote for you.",
  SignUpWelcomeMessageLabRecommendation:
    "Welcome {{firstName}}, activate your account to view the lab recommendation {{pracName}} wrote for you.",
  SignInWelcomeMessage:
    "Hi {{- firstName}}, please sign in to view the {{treatmentPlanTerm}} {{- pracName}} wrote for you.",
  SignInWelcomeMessageLabRecommendation:
    "Hi {{- firstName}}, please sign in to view the lab recommendation {{- pracName}} wrote for you.",
  SignUpDiscountSubtitle: "Sign up now to gain access to a {{discount}}% discount on supplements!",
  FreePriorityShipping: "Free priority shipping over ${{freeShippingThreshold}}",
  FreeStandardShipping: "Free shipping over ${{freeShippingThreshold}}",
  FreeStandardShippingZeroThreshold: "Free standard shipping",
  FreePriorityShippingZeroThreshold: "Free priority shipping",
  FooterTerms:
    "This page is protected by reCAPTCHA, and subject to the <1>Google Privacy Policy</1> and <3>Terms of Service</3>",
  Continue: "Continue",
  EnterCredentials: "Enter your credentials to add this store to your account.",
  ForgotPassword: "I forgot my password",
  CreateMyAccount: "Create my account",
  CreatePassword: "Create password",
  Email: "Email: {{patientEmail}}",
  SubmitButtonTooltip: "Please create a password in the field above to activate your account.",
  EmailTooltip: "You can edit your account information once you are signed in.",
  Logo: "{{storeName}} logo",
  AltHippa: "HIPPA compliant",
  AltCarbon: "Carbon Zero certified",
  ViewTreatmentPlan: "View {{treatmentPlanTerm}}",
  ActivateAccount: "Activate account",
  storeLandingPages: {
    leftColumn: {
      pracAvatar: "Avatar for {{pracName}}",
      tips: "{{storeName}} trusts Fullscript to power their dispensary. Here's why you should too:",
      carousel: {
        slide1:
          "Healthcare's best supplements and wellness products are shipped right to your door.",
        slide2:
          "Products are safer and more effective with higher ingredient, storage, and shipping standards.",
        slide3:
          "You can stay on track with refill reminders timed and personalized to recommended dosages.",
      },
    },
    backToEdit: "Back to edit",
    copyStoreLink: "Copy store link",
    copyLink: "Copy link",
    toastCopySuccessMessage: "Store link copied successfully",
  },
} as const;
