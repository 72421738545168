import { useTranslation } from "react-i18next";

import {
  Button,
  ButtonGroup,
  DropdownButton,
  DropdownContent,
  DropdownLink,
  Dropdown,
} from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./CountrySelect.styles";

interface Props {
  slug?: string;
}

const CountrySelect = ({ slug }: Props) => {
  const { isCanada } = useSharedGlobalConfig();
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const [isIAMLoginDS4] = useFlippers("iam_login_ds4");

  const USLink = slug ? authRoutes.usApp + slug : authRoutes.usApp;
  const CALink = slug ? authRoutes.caApp + slug : authRoutes.caApp;

  const getUSButtonStyle = [!isCanada && styles.activeButton];
  const getCAButtonStyle = [isCanada && styles.activeButton];

  if (tablet.lessThan || isIAMLoginDS4) {
    return (
      <Dropdown>
        <DropdownButton isText aria-label={isCanada ? t(l.common.CALogin) : t(l.common.USLogin)}>
          {isCanada ? <>🇨🇦 CA</> : <>🇺🇸 US</>}
        </DropdownButton>
        <DropdownContent>
          <DropdownLink aria-label={t(l.common.GoToUS)} href={USLink}>
            🇺🇸 US
          </DropdownLink>
          <DropdownLink aria-label={t(l.common.GoToCA)} href={CALink}>
            🇨🇦 CA
          </DropdownLink>
        </DropdownContent>
      </Dropdown>
    );
  }

  return (
    <ButtonGroup>
      <Button href={USLink} isColor="system" css={getUSButtonStyle} aria-label={t(l.common.GoToUS)}>
        🇺🇸 US
      </Button>
      <Button href={CALink} isColor="system" css={getCAButtonStyle} aria-label={t(l.common.GoToCA)}>
        🇨🇦 CA
      </Button>
    </ButtonGroup>
  );
};

export { CountrySelect };
